import React from 'react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'
import { keyframes } from '@emotion/core'

import { Section, SectionTitle } from '../AboutUsComponents'
import ApproachAnimation from './ApproachAnimation'

//For animation to start before getting to the section
const Viewer = styled('div')`
  position: absolute;
  top: -250px;
`

export default function OurApproachSection({
  ourApproachSectionTitle,
  ourApproachSteps,
}) {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <Section style={{ position: 'relative' }}>
      <Viewer ref={ref} />
      <SectionTitle mobileCenter>{ourApproachSectionTitle}</SectionTitle>
      <ApproachAnimation
        showAnimation={inView}
        ourApproachSteps={ourApproachSteps}
      />
    </Section>
  )
}
