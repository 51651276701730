import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import StickyNav from '../components/Navigation/StickyNav'
import About from '../components/About'
import { Helmet } from 'react-helmet'

const metaTitle = 'Who We Are - Leading Digital Product Studio | Crowdlinker'

const metaDescription =
  'We partner with businesses from various industries to transform their ideas into digital experiences. See our work for Nestle, League, Freshbooks, and more.'

const AboutUs = props => {
  const aboutUsData = props.data.contentfulAboutUs
  const ctaData = props.data.contentfulBlogHomepage

  return (
    <Layout footerCTA={true}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <StickyNav />
      <About aboutUsData={aboutUsData} ctaData={ctaData} />
    </Layout>
  )
}

export default AboutUs

export const pageQuery = graphql`
  query {
    contentfulAboutUs {
      heroTitle
      heroBody
      heroBackgroundImage {
        title
        sizes(maxWidth: 1000) {
          ...GatsbyContentfulSizes
        }
      }
      storySectionTitle
      storySectionBody {
        storySectionBody
      }
      storySectionTimeline {
        title
        description
      }
      statsSectionTitle
      statsSectionList {
        number
        text
      }
      whatMattersSectionTitle
      whatMattersSectionBody {
        whatMattersSectionBody
      }
      whatMattersSectionValues {
        title
        body {
          body
        }
      }
      expertiseSectionTitle
      expertiseSectionBody {
        expertiseSectionBody
      }
      expertiesSectionImages {
        title
        sizes(maxWidth: 1000) {
          ...GatsbyContentfulSizes
        }
      }
      ourApproachSectionTitle
      ourApproachSteps {
        title
        description
      }
      whoWeWorkWithSectionTitle
      whoWeWorkWithSectionBody {
        whoWeWorkWithSectionBody
      }
      whoWeWorkWithSectionPersonas {
        title
        body
      }
      whatMattersSectionQuotes {
        quote
        person
        title
        logo {
          title
          file {
            url
          }
        }
      }
      teamSectionTitle
      teamSectionBody
      teamSectionMembers {
        name
        title
        image {
          title
          sizes(maxWidth: 1020, maxHeight: 681, quality: 100) {
            ...GatsbyContentfulSizes
          }
        }
        department
      }
    }
    contentfulBlogHomepage {
      ctaTitle
      ctaDescription
      ctaPlaceholder
      ctaButtonText
      ctaSuccessHeader
      ctaSuccessBody
    }
  }
`
