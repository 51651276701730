import React from 'react'
import { useInView } from 'react-intersection-observer'
import {
  Section,
  InnerContainer,
  SplitSection,
  SectionTitle,
  SectionText,
  CtaWrapper,
} from '../AboutUsComponents'
import { GradientButton } from '../../Buttons'
import StoryAnimation from './StoryAnimation'

export default function StorySection({
  storySectionTitle,
  storySectionBody,
  storySectionTimeline,
}) {
  const [ref, inView] = useInView({ triggerOnce: true })
  return (
    <Section>
      <InnerContainer>
        <SplitSection width={'40%'}>
          <SectionTitle marginBottom={'25px'}>{storySectionTitle}</SectionTitle>
          <SectionText storySection={true}>
            {storySectionBody.storySectionBody}
          </SectionText>
          <CtaWrapper>
            <GradientButton
              whiteBtn={true}
              linkTo={'/our-work'}
              gradientLeft={'1px'}
              gradientTop={'1px'}
              gradientHeight="calc(100% - 2px)"
              gradientWidth="calc(100% - 2px)"
              ctaText={'See our work'}
              fontWeight={'500'}
              fontFamily={'primaryMedium'}
            />
          </CtaWrapper>
        </SplitSection>
        <SplitSection width={'60%'} ref={ref}>
          <StoryAnimation
            storySectionTimeline={storySectionTimeline}
            showAnimation={inView}
          />
        </SplitSection>
      </InnerContainer>
    </Section>
  )
}
