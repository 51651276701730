import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import rocketImage from '../../../images/rocket.svg'
import pivotImage from '../../../images/pivot.svg'

const Wrapper = styled('div')`
  min-height: 400px;
`
const Container = styled('div')`
  display: ${props => (props.showAnimation ? 'grid' : 'none')};
  grid-auto-rows: 1fr;
  width: 100%;
  margin: 104px 0 35px 20%;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin: 45px auto;
    padding-left: 10%;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 0 0 30px;
  }
`

const fadeIn = keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
`
const drawLine = keyframes`
    from { height: 0; }
    to   { height: calc(100% - 100px); }
`
const drawLineMobile = keyframes`
    from { height: 0; }
    to   { height: calc(100% - 70px); }
`
const rocketBackground = keyframes`
  0% { 
    background: white;
    box-shadow: 0 0 0 rgba(75, 28, 97, 0.1);
    }
    20% {
      background: #4B1C61;
      box-shadow: 0px 0px 0px 17px rgba(75, 28, 97, 0.1);
    }
    80% {
      background: #4B1C61;
      box-shadow: 0px 0px 0px 17px rgba(75, 28, 97, 0.1);
    }
  100% { 
    box-shadow: 0 0 0 rgba(75, 28, 97, 0.1);
    background: white;
  }
`
const pivotBackground = keyframes`
  0% { 
    background: white;
    box-shadow: 0 0 0 rgba(75, 28, 97, 0.1);
    }
    20% {
      background: #c11c5c;
      box-shadow: 0px 0px 0px 17px rgba(193, 28, 92, 0.1);
    }
    80% {
      background: #c11c5c;
      box-shadow: 0px 0px 0px 17px rgba(193, 28, 92, 0.1);
    }
  100% { 
    box-shadow: 0 0 0 rgba(75, 28, 97, 0.1);
    background: white;
  }
`
const scaleBackground = keyframes`
  0% { 
    background: white;
    box-shadow: 0 0 0 rgba(75, 28, 97, 0.1);
    }
    20% {
      background: #162033;
      box-shadow: 0px 0px 0px 17px rgba(22, 32, 51, 0.1);
    }
    80% {
      background: #162033;
      box-shadow: 0px 0px 0px 17px rgba(22, 32, 51, 0.1);
    }
  100% { 
    box-shadow: 0 0 0 rgba(75, 28, 97, 0.1);
    background: white;
  }
`

const scaleSmallBoxAnimation = keyframes`
  0% { 
    fill: rgb(22, 32, 51);
  }
  20% {
    fill: white;
  }
  80% {
    fill: white;
  }
  100% { 
    fill: rgb(22, 32, 51);
  }
`
const scaleBigBoxAnimation = keyframes`
  0% { 
    fill: white;
  }
  20% {
    fill: #FF89B7;
  }
  80% {
    fill: #FF89B7;
  }
  100% { 
    fill: white;
  }
`
const scaleOverlapAnimation = keyframes`
  0% { 
    fill: rgb(22, 32, 51);
  }
  20% {
    fill: #FF89B7;
  }
  80% {
    fill: #FF89B7;
  }
  100% { 
    fill: rgb(22, 32, 51);
  }
`

const ScaleboxOverlap = styled('path')`
  animation: ${scaleOverlapAnimation} 3s ease;
  animation-delay: 4.2s;
  animation-fill-mode: forwards;
`
const ScaleBigBox = styled('path')`
  animation: ${scaleBigBoxAnimation} 3s ease;
  animation-delay: 4.2s;
  animation-fill-mode: forwards;
`
const ScaleSmallBox = styled('path')`
  animation: ${scaleSmallBoxAnimation} 3s ease;
  animation-delay: 4.2s;
  animation-fill-mode: forwards;
`

const Item = styled('div')`
  position: relative;
  display: flex;
  min-height: 200px;
  margin: 0;
  padding-bottom: 35px;
  opacity: 0;
  animation: ${fadeIn} 1.8s ease;
  animation-fill-mode: forwards;

  &::after {
    content: '';
    width: 2px;
    position: absolute;
    top: 100px;
    left: 50px;
    animation: ${drawLine} 0.5s;
    animation-fill-mode: forwards;
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      top: 70px;
      left: 35px;
      animation: ${drawLineMobile} 0.5s;
      animation-fill-mode: forwards;
    }
  }
  &:nth-of-type(1) {
    &::after {
      animation-delay: 2.2s;
      background-image: linear-gradient(to bottom, #38215d, #c8266a);
    }
  }
  &:nth-of-type(2) {
    animation-delay: 2s;
    &::after {
      animation-delay: 4.2s;
      background-image: linear-gradient(to bottom, #e579ac, #051f33);
    }
  }
  &:nth-of-type(3) {
    animation-delay: 4s;
  }
`

const Circle = styled('div')`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-right: 28px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    height: 70px;
    width: 70px;
  }
  img,
  svg {
    height: 50px;
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      height: 38px;
    }
  }

  &.first {
    border: 1px solid #4b1c61;
    animation: ${rocketBackground} 2s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }
  &.second {
    border: 1px solid rgba(193, 28, 92, 0.9);
    animation: ${pivotBackground} 2s ease;
    animation-delay: 2.3s;
    animation-fill-mode: forwards;
  }
  &.third {
    border: 1px solid #102439;
    animation: ${scaleBackground} 3s ease;
    animation-delay: 4.3s;
    animation-fill-mode: forwards;
  }
`

const Content = styled('div')`
  width: 62%;
  margin-top: 8px;
  h3 {
    font-family: ${props => props.theme.fonts.sourceCode};
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    margin: 0 0 7px;
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 15px;
    }
  }
  p {
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 21px;
    line-height: 1.24;
    margin: 0;
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 17px;
    }
  }
`

export default function StoryAnimation({
  storySectionTimeline,
  showAnimation,
}) {
  return (
    <Wrapper>
      <Container showAnimation={showAnimation}>
        <Item>
          <Circle className="first">
            <img src={rocketImage} alt="rocket" />
          </Circle>

          <Content>
            <h3>{storySectionTimeline[0].title}</h3>
            <p>{storySectionTimeline[0].description}</p>
          </Content>
        </Item>
        <Item>
          <Circle className="second">
            <img src={pivotImage} alt="pivot" />
          </Circle>
          <Content>
            <h3>{storySectionTimeline[1].title}</h3>
            <p>{storySectionTimeline[1].description}</p>
          </Content>
        </Item>
        <Item>
          <Circle className="third">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
            >
              <g fill="none" fillRule="evenodd" strokeWidth=".5">
                <ScaleSmallBox
                  fill="#162033"
                  stroke="#162033"
                  d="M.25 28.25h20.5v20.5H.25z"
                  className="scale-box-2"
                />
                <ScaleBigBox
                  stroke="#C11C5C"
                  stroke-opacity=".9"
                  d="M11 28.388V1h37v37H20.84M33.996 16L42 8.005"
                  className="scale-box-1"
                />
                <path stroke="#C11C5C" strokeOpacity=".9" d="M38 8.005h4v4" />
                <ScaleboxOverlap
                  stroke="#FFF"
                  d="M11 28v10h10"
                  className="scale-box-overlap"
                />
              </g>
            </svg>
          </Circle>
          <Content>
            <h3>{storySectionTimeline[2].title}</h3>
            <p>{storySectionTimeline[2].description}</p>
          </Content>
        </Item>
      </Container>
    </Wrapper>
  )
}
