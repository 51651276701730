import React from 'react'
import Subscribe from '../Blog/Subscribe'
import HeroSection from './HeroSection'
import StorySection from './StorySection'
import WhatMattersSection from './WhatMattersSection'
import ExpertiseSection from './ExpertiseSection'
import OurApproachSection from './OurApproachSection'
import WhoWeWorkWithSection from './WhoWeWorkWithSection'
import TeamSection from './TeamSection'
import LogosBanner from '../Shared/Banners/Logos'
import StatsBanner from '../Shared/Banners/Stats'

export default function About(props) {
  const {
    ctaData,
    aboutUsData: {
      heroTitle,
      heroBody,
      heroBackgroundImage,
      storySectionTitle,
      storySectionBody,
      statsSectionTitle,
      statsSectionList,
      whatMattersSectionTitle,
      whatMattersSectionBody,
      whatMattersSectionValues,
      expertiseSectionTitle,
      expertiseSectionBody,
      expertiesSectionImages,
      ourApproachSectionTitle,
      ourApproachSteps,
      whoWeWorkWithSectionTitle,
      whoWeWorkWithSectionBody,
      whoWeWorkWithSectionPersonas,
      whatMattersSectionQuotes,
      teamSectionTitle,
      teamSectionBody,
      teamSectionMembers,
      storySectionTimeline,
    },
  } = props

  return (
    <React.Fragment>
      <HeroSection
        heroTitle={heroTitle}
        heroBody={heroBody}
        heroBackgroundImage={heroBackgroundImage}
      />
      <StorySection
        storySectionTitle={storySectionTitle}
        storySectionBody={storySectionBody}
        storySectionTimeline={storySectionTimeline}
      />
      <StatsBanner
        statsSectionTitle={statsSectionTitle}
        statsSectionList={statsSectionList}
      />
      <WhatMattersSection
        whatMattersSectionTitle={whatMattersSectionTitle}
        whatMattersSectionBody={whatMattersSectionBody}
        whatMattersSectionValues={whatMattersSectionValues}
        whatMattersSectionQuotes={whatMattersSectionQuotes}
      />
      <ExpertiseSection
        expertiseSectionTitle={expertiseSectionTitle}
        expertiseSectionBody={expertiseSectionBody}
        expertiesSectionImages={expertiesSectionImages}
      />
      <OurApproachSection
        ourApproachSectionTitle={ourApproachSectionTitle}
        ourApproachSteps={ourApproachSteps}
      />
      <WhoWeWorkWithSection
        whoWeWorkWithSectionTitle={whoWeWorkWithSectionTitle}
        whoWeWorkWithSectionBody={whoWeWorkWithSectionBody}
        whoWeWorkWithSectionPersonas={whoWeWorkWithSectionPersonas}
      />
      <LogosBanner />
      <TeamSection
        teamSectionTitle={teamSectionTitle}
        teamSectionBody={teamSectionBody}
        teamSectionMembers={teamSectionMembers}
      />
      <Subscribe
        key="newsletter-subscribe"
        ctaData={ctaData}
        useAsBanner={true}
      />
    </React.Fragment>
  )
}
