import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import useWindowSize from '../../../util/useWindowSize'

// import CapabilitiesDeckCTA from './CapabilitiesDeckCTA'
import line1 from '../../../images/approach-line1.svg'
import line2 from '../../../images/approach-line2.svg'
import line3 from '../../../images/approach-line3.svg'
import line1Mobile from '../../../images/approach-line1-mobile.svg'
import line2Mobile from '../../../images/approach-line2-mobile.svg'
import line3Mobile from '../../../images/approach-line3-mobile.svg'

const fadeIn = keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
`
const revealHorizontalLine = keyframes`
    from { width: 100%; }
    to   { width: 0; }
`
const revealVerticalLine = keyframes`
    from { height: 100%; }
    to   { height: 0; }
`
const drawVerticalLine = keyframes`
    from { height: 5px; }
    to   { height: 35px; }
`
const drawHorizontalLine = keyframes`
    from { width: 6px; }
    to   { width: 30px; }
`
const dot1Animation = keyframes`
  0% { 
    opacity: 0;
    box-shadow: 0 0 0 rgba(56,33,93,0.1);
  }
  50% {
    box-shadow: 0px 0px 0px 8px rgba(56,33,93,0.3);
  }
  100% { 
    box-shadow: 0 0 0 rgba(56,33,93,0.1);
    opacity: 1;
  }
`
const dot2Animation = keyframes`
  0% { 
    opacity: 0;
    box-shadow: 0 0 0 rgba(56,33,93,0.1);
  }
  50% {
    box-shadow: 0px 0px 0px 8px rgba(193,28,92,0.3);
  }
  100% { 
    box-shadow: 0 0 0 rgba(56,33,93,0.1);
    opacity: 1;
  }
`
const dot3Animation = keyframes`
  0% { 
    opacity: 0;
    box-shadow: 0 0 0 rgba(56,33,93,0.1);

  }
  50% {
    box-shadow: 0px 0px 0px 8px rgba(193,28,92,0.3);
  }
100% { 
    box-shadow: 0 0 0 rgba(56,33,93,0.1);
    opacity: 1;
  }
`
const dot4Animation = keyframes`
  0% { 
    opacity: 0;
    box-shadow: 0 0 0 rgba(56,33,93,0.1);
  }
  50% {
    box-shadow: 0px 0px 0px 8px rgba(22,32,51,0.3);
  }
  100% { 
    box-shadow: 0 0 0 rgba(56,33,93,0.1);
    opacity: 1;
  }
`

const Wrapper = styled('div')`
  position: relative;
  display: ${props => (props.showAnimation ? 'flex' : 'none')};
  margin-top: 85px;
  margin-left: 13px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    display: ${props => (props.showAnimation ? 'flex' : 'none')};
    flex-direction: column;
    margin-top: 40px;
    justify-content: center;
    padding-bottom: 0;
  }
`
const ApproachStepContainer = styled('div')`
  position: relative;
  width: 415px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    position: relative;
    display: flex;
    width: 375px;
    height: 140px;
    margin: 0 auto;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
    height: 125px;
  }
  img {
    z-index: 0;
    width: 100%;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      z-index: 0;
      height: 92%;
      width: auto;
      transform: translate(-8px, 13px);
      transform-origin: center;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      height: 94%;
      transform: translate(-8px, 10px);
    }
  }
  p {
    margin-top: 30px;
  }
  .reveal-bar {
    animation: ${revealHorizontalLine} 1.5s ease;
    animation-fill-mode: forwards;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      animation: ${revealVerticalLine} 1.5s ease;
      animation-fill-mode: forwards;
    }
  }
  &:nth-of-type(1) .reveal-bar {
    animation-delay: 1s;
  }
  &:nth-of-type(2) .reveal-bar {
    animation-delay: 3.5s;
  }
  &:nth-of-type(3) .reveal-bar {
    animation-delay: 6s;
  }
`
const Dot = styled('div')`
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: translate(-9px, -1px);
  opacity: 0;
  z-index: 3;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    top: 0;
    left: 0;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 15px;
    height: 15px;
  }
  &::before {
    content: '';
    width: 2px;
    position: absolute;
    top: 18px;
    left: 8px;
    background: inherit;
    animation: ${drawVerticalLine} 1s ease-in;
    animation-fill-mode: forwards;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      animation: ${drawHorizontalLine} 1s ease-in;
      animation-fill-mode: forwards;
      height: 2px;
      top: 9px;
      left: 18px;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      top: 7px;
      left: 14px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 48px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    animation: none;
    background: inherit;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      top: 6px;
      left: 46px;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      top: 4px;
      left: 40px;
    }
  }
  &.dot1 {
    background-color: #38215d;
    animation: ${dot1Animation} 1s;
    animation-fill-mode: forwards;
  }
  &.dot2 {
    background-color: #c8266a;
    animation: ${dot2Animation} 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    &::before {
      animation-delay: 2s;
    }
  }
  &.dot3 {
    background-color: #e579ac;
    animation: ${dot3Animation} 1s;
    animation-delay: 4.5s;
    animation-fill-mode: forwards;
    &::before {
      animation-delay: 4.5s;
    }
  }
  &.dot4 {
    background-color: #162033;
    animation: ${dot4Animation} 1s;
    animation-delay: 7s;
    animation-fill-mode: forwards;
    &::before {
      animation-delay: 7s;
    }
  }
`
const RevealBar = styled('div')`
  position: absolute;
  right: 0;
  height: 50px;
  width: 100%;
  background: white;
  z-index: 2;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    right: auto;
    left: -8px;
    bottom: -2px;
    height: 100%;
    width: 20px;
  }
`
const Text = styled('div')`
  position: absolute;
  left: 9px;
  top: 45px;
  max-width: 235px;
  opacity: 0;
  animation: ${fadeIn} 1s;
  animation-fill-mode: forwards;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    left: 72px;
    top: -11px;
    max-width: 325px;
  }
  &.text2 {
    animation-delay: 2s;
  }
  &.text3 {
    animation-delay: 4.5s;
  }
  &.text4 {
    animation-delay: 7s;
  }
  h3 {
    font-family: ${props => props.theme.fonts.sourceCode};
    font-size: 18px;
    line-height: 1.89;
    letter-spacing: 1px;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      font-size: 21px;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 15px;
    }
  }
  p {
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 21px;
    line-height: 1.05;
    margin: 0;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      margin: 5px 0 0;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 17px;
    }
  }
`

const Spacer = styled('div')`
  height: 200px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    display: none;
  }
`

export default function ApproachAnimation({ showAnimation, ourApproachSteps }) {
  const screenSize = useWindowSize()

  const lineImages = [
    { desktop: line1, mobile: line1Mobile },
    { desktop: line2, mobile: line2Mobile },
    { desktop: line3, mobile: line3Mobile },
  ]
  const stepsLength = ourApproachSteps.length

  const steps = ourApproachSteps.map((step, i) => {
    if (i + 1 === stepsLength) {
      return (
        <ApproachStepContainer key={i}>
          <RevealBar className="reveal-bar" />
          <Dot className={`dot${i + 1}`} />
          <Text className={`text${i + 1}`}>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </Text>
          {/* // TODO Enable this once we have the email & email copy ready to send deck with */}
          {/* <CapabilitiesDeckCTA /> */}
          {/* // TODO Remove spacer below after deck CTA launch */}
          <Spacer />
        </ApproachStepContainer>
      )
    }
    return (
      <ApproachStepContainer key={i}>
        <RevealBar className="reveal-bar" />
        <img
          src={
            screenSize === 'desktop'
              ? lineImages[i].desktop
              : lineImages[i].mobile
          }
          alt="curved-line"
        />
        <Dot className={`dot${i + 1}`} />
        <Text className={`text${i + 1}`}>
          <h3>{step.title}</h3>
          <p>{step.description}</p>
        </Text>
      </ApproachStepContainer>
    )
  })

  return <Wrapper showAnimation={showAnimation}>{steps}</Wrapper>
}
