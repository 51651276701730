import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import lightGradientBackground from '../../../images/light-gradient-background.png'
import { Section, SectionTitle, SectionSubTitle } from '../AboutUsComponents'
import { GradientButton } from '../../Buttons'

const WorkWithContainer = styled('div')`
  display: flex;
  justify-content: space-evenly;
  margin: 87px 0 20px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    flex-direction: column;
    margin-top: 30px;
  }
`

const WorkWithCard = styled('div')`
  text-align: center;
  line-height: 1.4;
  width: 28%;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
  }
  h3 {
    font-family: ${props => props.theme.fonts.sourceCode};
    font-size: 24px;
    letter-spacing: 1.3px;
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 18px;
      margin-top: 20px;
    }
  }
  p {
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 23px;
    margin-top: 8px;
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 21px;
    }
  }
`

const CtaContainer = styled('div')`
  text-align: center;
`

export default function WhoWeWorkWithSection({
  whoWeWorkWithSectionTitle,
  whoWeWorkWithSectionBody,
  whoWeWorkWithSectionPersonas,
}) {
  const personaList = whoWeWorkWithSectionPersonas.map((persona, i) => (
    <WorkWithCard key={i}>
      <h3>{persona.title}</h3>
      <p>{persona.body}</p>
    </WorkWithCard>
  ))
  return (
    <Section backgroundImage={lightGradientBackground}>
      <Fade>
        <SectionTitle center mobileLeft>
          {whoWeWorkWithSectionTitle}
        </SectionTitle>
        <SectionSubTitle center>
          {whoWeWorkWithSectionBody.whoWeWorkWithSectionBody}
        </SectionSubTitle>
      </Fade>

      <Fade delay={400}>
        <WorkWithContainer>{personaList}</WorkWithContainer>
      </Fade>
      <CtaContainer>
        <GradientButton />
      </CtaContainer>
    </Section>
  )
}
