import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import { CTA, CTAText } from '../../Buttons'
import getRandomArray from '../../../util/getRandomArray'
import useWindowSize from '../../../util/useWindowSize'
import { Link } from 'gatsby'

const CTAButton = CTA.withComponent(Link)

const Wrapper = styled('div')`
  padding: 40px;
  background: white;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 0;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 10px;
  }
`

const Header = styled('h2')`
  color: ${props => props.theme.colors.magenta};
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 60px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  margin-bottom: '12px';
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 38px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 34px;
  }
`

const Body = styled('h3')`
  color: black;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.17;
  width: 100%;
  padding: 25px 0 50px;
  margin: ${props => (props.center ? '0 auto' : '0')};
  text-align: center;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 21px;
    line-height: 1.4;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 20px;
  }
`

const Container = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
  max-width: 1360px;
`
const Card = styled(BackgroundImage)`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 25%;
  height: 227px;
  position: relative;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 33.3%;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 50%;
    height: 100px;
  }
  &:hover {
    p {
      opacity: 1;
    }
  }
  p {
    opacity: 0;
    transition: all 0.4s;
    font-family: ${props => props.theme.fonts.sourceCode};
    color: white;
    background: black;
    position: absolute;
    bottom: 0;
    font-size: 15px;
    letter-spacing: 0.8px;
    color: white;
    padding: 8px;
    margin: 12px 16px;
    span {
      color: ${props => props.titlecolor};
    }
  }
`

const BlankCard = styled('div')`
  background: white;
  width: 25%;
  height: 227px;
  position: relative;
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-weight: 500;
  font-size: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f4f2;
  text-align: center;
  a {
    cursor: pointer;
    text-decoration: none;
    color: black;
  }
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 33.3%;
    &.join {
      width: 66.6%;
    }
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    display: none;
  }
`

const TeamSectionContainer = styled('div')`
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  top: 227px;
  bottom: 0;
  margin: auto;
  padding: 8%;
  width: 51%;
  height: 701px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 2px 6px 20px 0 rgba(0, 0, 0, 0.1);
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    height: 465px;
    width: 37%;
    padding: 3%;
    top: 226px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    position: relative;
    width: 100%;
    height: auto;
    padding: 18%;
    box-shadow: none;
    top: 0;
  }
`

export default function TeamSection({
  teamSectionTitle,
  teamSectionBody,
  teamSectionMembers,
}) {
  const screenSize = useWindowSize()

  const teamMemebers =
    screenSize === 'mobile'
      ? getRandomArray(teamSectionMembers, 10)
      : teamSectionMembers

  const memberphotos = teamMemebers.map((member, i) => {
    if (screenSize === 'desktop' && (i === 9 || i === 11 || i === 13)) {
      return (
        <React.Fragment key={i}>
          <TeamMemberCard blank={true} />
          <TeamMemberCard blank={true} />
          <TeamMemberCard member={member} />
        </React.Fragment>
      )
    }
    if (screenSize === 'tablet' && (i === 10 || i === 12)) {
      return (
        <React.Fragment key={i}>
          <TeamMemberCard blank={true} />
          <TeamMemberCard member={member} />
        </React.Fragment>
      )
    } else {
      return <TeamMemberCard member={member} key={i} />
    }
  })

  return (
    <Wrapper>
      <Container>
        {memberphotos}
        <BlankCard className="join">
          <Link to="/careers#jobs">Join the party.</Link>
        </BlankCard>
        <TeamSectionContainer>
          <Header>{teamSectionTitle}</Header>
          <Body>{teamSectionBody}</Body>
          <CTAButton
            to="/careers"
            whiteBtn={true}
            gradientLeft={'1px'}
            gradientTop={'1px'}
            gradientHeight="calc(100% - 2px)"
            gradientWidth="calc(100% - 2px)"
            fontWeight={'500'}
            fontFamily={'primaryMedium'}
            padding={'0 22px'}
          >
            <CTAText>See job openings</CTAText>
          </CTAButton>
        </TeamSectionContainer>
      </Container>
    </Wrapper>
  )
}

function TeamMemberCard({ member, blank }) {
  if (blank) {
    return <BlankCard />
  }

  let titlecolor
  switch (member.department) {
    case 'development':
      titlecolor = '#009bec'
      break
    case 'product management':
      titlecolor = '#a5c93d'
      break
    case 'design':
      titlecolor = '#ff003b'
      break
    case 'marketing':
      titlecolor = '#deac54'
      break
    case 'management':
      titlecolor = 'rgba(193, 28, 92, 0.9)'
      break
    default:
      titlecolor = '#009bec'
  }

  return (
    <Card sizes={member.image.sizes} titlecolor={titlecolor}>
      <p>
        {member.name}, <span>{member.title}</span>
      </p>
    </Card>
  )
}
