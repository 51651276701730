import React from 'react'
import Fade from 'react-reveal/Fade'
import {
  Section,
  SectionTitle,
  SectionSubTitle,
  InnerContainer,
  SplitSection,
  SectionCategoryText,
  SectionText,
} from '../AboutUsComponents'
import AnimatedQuotes from '../../Shared/Quotes/Animated'

export default function WhatMattersSection({
  whatMattersSectionTitle,
  whatMattersSectionBody,
  whatMattersSectionValues,
  whatMattersSectionQuotes,
}) {
  const valuesList = whatMattersSectionValues.map((value, i) => (
    <React.Fragment key={i}>
      <SectionCategoryText>{value.title}</SectionCategoryText>
      <SectionText>{value.body.body}</SectionText>
    </React.Fragment>
  ))

  return (
    <Section>
      <Fade>
        <SectionTitle center mobileLeft>
          {whatMattersSectionTitle}
        </SectionTitle>
        <SectionSubTitle center>
          {whatMattersSectionBody.whatMattersSectionBody}
        </SectionSubTitle>
      </Fade>
      <InnerContainer marginTop={'90px'}>
        <SplitSection width={'40%'}>
          <Fade delay={400}>{valuesList}</Fade>
        </SplitSection>
        <SplitSection width={'60%'} padding={'0 0 0 7%'}>
          <AnimatedQuotes quotes={whatMattersSectionQuotes} />
        </SplitSection>
      </InnerContainer>
    </Section>
  )
}
